import { useSelf } from "~/domains/member/composables";

export default function () {
  const route = useRoute();
  const { data: self } = useSelf();

  enum Routes {
    HOME = "/",
    ABOUT = "/about",
    CAMPAIGNS = "/campaigns",
    WHO_WE_ARE = "/who-we-are",
    HOW_IT_WORKS = "/how-it-works",
    HUMANITYS_CHECKLIST = "/humanitys-checklist",
    FOR_BUSINESS = "/for-business",
    SIGN_UP = "/sign-up",
    THEORY_OF_GIVING = "/theory-of-giving",
    PROFILE = "/profile",
    LOGIN = "/login",
    LOGOUT = "/logout",
    YELLOW_ROOMS = "/yellow-rooms",
    PLASTIC_FISCHER = "/plastic-fischer",
    OCEAN_VOYAGES = "/ocean-voyages",
    WILDLIFE = "/wildlife",
    FAQ = "/faq",
    QUESTS = "/quests",
    REFUNDS = "/refunds",
  }

  const routesData = [
    {
      path: Routes.ABOUT,
      color: "ocean-none",
      title: "About us",
    },
    {
      path: Routes.CAMPAIGNS,
      color: "nature",
      title: "Campaigns",
    },
    {
      path: Routes.WHO_WE_ARE,
      color: "blue",
      title: "Who We Are",
    },
    {
      path: Routes.HOW_IT_WORKS,
      color: "ocean-none",
      title: "How It Works",
    },
    {
      path: Routes.HUMANITYS_CHECKLIST,
      color: "ocean-pink-ocean",
      title: "Humanity's Checklist",
    },
    {
      path: Routes.THEORY_OF_GIVING,
      color: "pink",
      title: "Theory of Giving",
    },
    {
      path: Routes.FOR_BUSINESS,
      color: "green-white-ocean",
      title: "For business",
    },
    {
      path: Routes.PROFILE,
      color: "ocean-pink",
    },
    {
      path: Routes.YELLOW_ROOMS,
      color: "yellow-low",
      title: "Yellow Rooms",
    },
    {
      path: Routes.LOGIN,
      color: "ocean-none",
      title: "Sign In - Dollar Donation Club",
    },
  ];

  const navRoutes = computed(() => {
    const baseRoutes = [
      {
        name: "About",
        href: Routes.ABOUT,
        thumbnail: "/images/navbar/thumbnails/about-us.png",
      },
      {
        name: "Campaigns",
        href: Routes.CAMPAIGNS,
        thumbnail: "/images/navbar/thumbnails/campaigns.png",
      },
      {
        name: "Who we are",
        href: Routes.WHO_WE_ARE,
        thumbnail: "/images/navbar/thumbnails/who-we-are.png",
      },
      {
        name: "How it works",
        href: Routes.HOW_IT_WORKS,
        thumbnail: "/images/navbar/thumbnails/how-it-works.png",
      },
      {
        name: "Humanity's checklist",
        href: Routes.HUMANITYS_CHECKLIST,
        thumbnail: "/images/navbar/thumbnails/humanitys-checklist.png",
      },
      {
        name: "For business",
        href: Routes.FOR_BUSINESS,
        thumbnail: "/images/navbar/thumbnails/for-business.png",
      },
      {
        name: "Store",
        href: "https://store.dollardonationclub.com",
        thumbnail: "/images/navbar/thumbnails/store.jpg",
        external: true,
      },
    ];

    if (self.value) {
      baseRoutes.push({
        name: "Logout",
        href: Routes.LOGOUT,
        thumbnail: "/images/navbar/thumbnails/logout.png",
      });
    } else {
      baseRoutes.push({
        name: "Sign in",
        href: Routes.LOGIN,
        thumbnail: "/images/navbar/thumbnails/sign-in.png",
      });
    }

    return baseRoutes;
  });

  const footerLinks = new Map();
  footerLinks.set("Fun", {
    children: [
      {
        title: "Home",
        url: Routes.HOME,
      },
      {
        title: "About",
        url: Routes.ABOUT,
      },
      {
        title: "Who We Are",
        url: Routes.WHO_WE_ARE,
      },
      {
        title: "How it Works",
        url: Routes.HOW_IT_WORKS,
      },
      {
        title: "Sign Up",
        url: Routes.SIGN_UP,
      },
    ],
  });
  footerLinks.set("MoreFun", {
    children: [
      {
        title: "Theory of Giving",
        url: Routes.THEORY_OF_GIVING,
      },
      {
        title: "For Business",
        url: Routes.FOR_BUSINESS,
      },
      {
        title: "FAQ",
        url: Routes.FAQ,
      },
      {
        title: "Humanity's checklist",
        url: Routes.HUMANITYS_CHECKLIST,
      },
    ],
  });
  footerLinks.set("Connect", {
    children: [
      {
        title: "Instagram",
        url: "https://www.instagram.com/dollardonationclub",
      },
      {
        title: "Facebook",
        url: "https://www.facebook.com/Dollar-Donation-Club-1259773994201462",
      },
    ],
  });
  footerLinks.set("Boring", {
    children: [
      {
        title: "Privacy Policy",
        url: "https://app.termly.io/policy-viewer/policy.html?policyUUID=e5344696-998a-41ba-b8b8-2b2a140d92bd",
      },
      {
        title: "Terms of Use",
        url: "https://app.termly.io/policy-viewer/policy.html?policyUUID=403554a8-9548-481a-9f10-feb1a00b8d7a",
      },
    ],
  });

  const getNavRoutes = () => navRoutes;
  const getFooterLinks = () => footerLinks;

  const getRouteTitle = () => {
    const path = route.fullPath.split("#");
    const res = routesData.find((route) => route.path === path[0]);
    return res?.title ?? "Dollar Donation Club";
  };

  return { getNavRoutes, getFooterLinks, Routes, getRouteTitle };
}
